<template>
  <div class="category-cards-container w-100">
    <swiper
        :slides-per-view="7"
        :space-between="20"
        :navigation="true"
        :pagination="{ clickable: true }"
        class="mySwiper "
    >
      <!-- Loop through categoriesData to display category slides -->
      <swiper-slide v-for="category in categoriesData" :key="category.id" class="card">
        <a href="#" class="text-decoration-none" @click.prevent="navigateToCategory(category.id)">
          <h3>{{ category.title }}</h3>
        </a>
        <div class="card-header">
          <img :src="category.icon || defaultImage" :alt="category.title" class="card-image"/>
        </div>
        <ul class="card-list">
          <!-- Loop through subcategories for each category -->
          <li v-for="subcategory in category.subcategories" :key="subcategory.id">
            <a href="#" class="item-link" @click.prevent="navigateToCategory(category.id, subcategory.id)">
              {{ subcategory.name }}
              <span v-if="isPopularSubcategory(subcategory.id)" class="tag">Popular</span>
            </a>
          </li>
        </ul>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

export default {
  name: 'MegaMenu',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    categoriesData: {
      type: Array,
      required: true,
    },
    productsData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      defaultImage: "https://safenet.bitquintet.com/media/images/Frame_1000006107.png",
    };
  },
  methods: {
    // Check if a subcategory has popular products
    isPopularSubcategory(subcategoryId) {
      return this.productsData.some(
        (product) => product.subcategory.id === subcategoryId && product.status === "POPULAR"
      );
    },
    navigateToCategory(categoryId, subcategoryId) {
      window.location.href = `/all-product?category=${categoryId}&subcategory=${subcategoryId}`;
    },
  },
};
</script>


<style scoped>
@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
.category-cards-container {
  padding: 20px 0;
}
.swiper.mySwiper {
  /*overflow: scroll !important;*/
}
.card {
  max-width: 20rem;
  min-width: 10rem;
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  border: none;

}

.card h3 {
  color: #1E1E1E;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  margin-bottom: 20px;
  min-height: 50px;
}

.card-header {
  margin-bottom: 10px;
  padding: 0;
  border: none;
}

.card-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 4px;
}

.card-list {
  list-style: none;
  padding: 0;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.item-link {
  text-decoration: none;
  color: #787878;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: color 0.3s ease;
}

.item-link:hover {
  color: #1E1E1E;
}

.tag {
  background-color: #ff9800;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8em;
}

/* Swiper custom styles */
:deep(.swiper-button-next),
:deep(.swiper-button-prev) {
  color: #1E1E1E;
  display: none;
}


:deep(.swiper-pagination-bullet-active) {
  background-color: #1E1E1E;
}
</style>