<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li
          v-for="(item, index) in parsedBreadcrumbItems"
          :key="index"
          :class="['breadcrumb-item', { 'active': item.isActive }]"
          :aria-current="item.isActive ? 'page' : null"
      >
        <div v-if="index === 0" class="d-flex align-items-center gap-2">
          <!-- SVG Icon -->
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
            <path
                d="M1 5.8125L8.875 1L16.75 5.8125M15 8.875V14.475C15 14.765 14.765 15 14.475 15H3.275C2.98505 15 2.75 14.765 2.75 14.475V8.875"
                stroke="#0F1E2A"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
          </svg>
          <a v-if="!item.isActive" :href="item.href">{{ item.text }}</a>
          <span v-else>{{ item.text }}</span>
        </div>

        <template v-else>
          <a v-if="!item.isActive" :href="item.href">{{ item.text }}</a>
          <span v-else>{{ item.text }}</span>
        </template>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: "BreadCrumb",
  props: {
    breadcrumbItems: {
      type: String,
      required: true
    }
  },
  computed: {
    parsedBreadcrumbItems() {
      try {
        return JSON.parse(this.breadcrumbItems);
      } catch (error) {
        console.error("Failed to parse breadcrumbItems JSON:", error);
        return [];
      }
    }
  }
};
</script>

<style scoped>



.breadcrumb {
  background: #F6F6F6;
  padding: 20px 80px;
}

.breadcrumb-item a {
  text-decoration: none;
  color: #1E1E1E;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 85.714% */
  letter-spacing: 0.5px;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: '';
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: solid #1E1E1E;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  margin-right: 0px;
  transform: rotate(-45deg);
  transition: transform 0.3s ease;
  height: 0;
  width: 0;
}

@media only screen and (max-width: 375px) {
  .breadcrumb-item a {
    text-decoration: none;
    color: #1E1E1E;
    font-size: 12px;

  }

  .breadcrumb {
    background: #F6F6F6;
    padding: 20px !important;
  }

  .breadcrumb-item.active {
    font-size: 12px;
  }

}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .breadcrumb-item a {
    text-decoration: none;
    color: #1E1E1E;
    font-size: 12px;

  }

  .breadcrumb {
    background: #F6F6F6;
    padding: 20px !important;
  }

  .breadcrumb-item.active {
    font-size: 12px;
  }


}

@media only screen and (min-width: 426px) and (max-width: 767px) {
  .breadcrumb-item a {
    text-decoration: none;
    color: #1E1E1E;
    font-size: 12px;

  }

  .breadcrumb {
    background: #F6F6F6;
    padding: 20px !important;
  }

}

</style>
