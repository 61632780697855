<template>
  <div>
    <nav aria-label="breadcrumb" class="d-none d-lg-block">
      <ol class="breadcrumb">
        <li class="breadcrumb-item d-flex align-items-center gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
            <path d="M1 5.8125L8.875 1L16.75 5.8125M15 8.875V14.475C15 14.765 14.765 15 14.475 15H3.275C2.98505 15 2.75 14.765 2.75 14.475V8.875" stroke="#0F1E2A" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <a href="#">Home</a>
        </li>
        <li class="breadcrumb-item"><a href="#">About</a></li>
      </ol>
    </nav>

    <div class="py-5 story_area overflow-hidden" style="padding: 80px">
      <div class="text-center d-flex flex-column align-items-center mb-4">
        <h2 class="story-heading">
          <span class="me-2">—</span>
          Since 1982 Our Story
          <span class="ms-2">—</span>
        </h2>
        <p class="text-muted " style="max-width: 650px">
          Safenet is Bangladesh's only homegrown brand specializing in structured LAN <br> infrastructure, providing
          reliable networking solutions since 2010.
        </p>
      </div>

      <div class="position-relative" style="margin-bottom: 100px;">
        <div class="circular-text mb-5">
          <div class="container">
            <div class="circular-text-container">
              <div class="circular-text">
                <span v-for="(char, index) in circularText" :key="index"
                      :style="{ transform: `rotate(${index * 10}deg)` }">
                  {{ char }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div type="button" class="play-button" data-bs-toggle="modal" data-bs-target="#exampleModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
            <path d="M1 1L15 10L1 19V1Z" fill="#222222" stroke="#0F172A" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </div>
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" style="max-width: 50%">
        <div class="modal-content" style="background: transparent; border: none">
          <div class="modal-body w-100" style="height: 550px">
            <iframe
                ref="videoIframe"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/GJWzKzndgRs?si=0utfNAeoL9U9p9ZT&enablejsapi=1"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
      </div>
      <div class="container">
      <div class="row align-items-center mb-5">
        <div class="col-12 col-lg-6">
          <div class="d-flex align-items-baseline mb-4">
            <span class="big-number">15</span>
            <h3 class="ms-3 mb-0 year">Years</h3>
          </div>
          <h4 class="mb-3 lan">Bangladesh's First Structured LAN Infrastructure Brand</h4>
          <p class="text-muted lan-text">
            "Digital Bangladesh" with this slogan Bangladesh is fast pacing toward the title of "Developing
            country". Safenet - aligned its core goal with this concept and contributing every possible aspect of
            "Digital Bangladesh" of the country. Safenet, today is which services and serving high-quality
            components calculated to match the needs in the different vertical markets of the ICT industry.
          </p>
        </div>
        <div class="col-12 col-lg-6">
          <div class="bg-light" style="height: 400px;">
            <img class="w-100 h-100" style="border-radius: 15px" src="https://safenet.bitquintet.com/media/images/Company_related_img.webp">
          </div>
        </div>
      </div>
        </div>

      <div class="d-flex justify-content-center flex-column flex-lg-row align-items-center  ">
        <div class="circle-button d-flex flex-column" style="padding: 30px">
          <h4>MISSION</h4>
          <p class="">Offer such products that it will take on an identity by itself and stand for loyalty, trust, faith along with at its finest.</p>
        </div>
        <div class="circle-button">
          <svg xmlns="http://www.w3.org/2000/svg" width="88" height="84" viewBox="0 0 88 84" fill="none">
            <path
                d="M42.5734 2.00861C43.0224 0.626645 44.9776 0.626641 45.4266 2.00861L54.3011 29.3217C54.6358 30.3517 55.5957 31.0491 56.6788 31.0491H85.3975C86.8505 31.0491 87.4547 32.9086 86.2791 33.7627L63.0452 50.6431C62.169 51.2797 61.8024 52.4081 62.1371 53.4382L71.0116 80.7513C71.4607 82.1332 69.8789 83.2824 68.7034 82.4283L45.4695 65.5479C44.5933 64.9113 43.4068 64.9113 42.5305 65.5479L19.2966 82.4283C18.1211 83.2824 16.5394 82.1332 16.9884 80.7513L25.8629 53.4382C26.1976 52.4081 25.831 51.2797 24.9548 50.6431L1.72086 33.7627C0.545291 32.9086 1.14945 31.0491 2.60254 31.0491H31.3212C32.4043 31.0491 33.3642 30.3517 33.6989 29.3217L42.5734 2.00861Z"
                fill="url(#paint0_linear_314_1390)" stroke="url(#paint1_linear_314_1390)"/>
            <defs>
              <linearGradient id="paint0_linear_314_1390" x1="44.0468" y1="-4" x2="44.0469" y2="96"
                              gradientUnits="userSpaceOnUse">
                <stop stop-color="#313131"/>
                <stop offset="1" stop-color="#1E1E1E"/>
              </linearGradient>
              <linearGradient id="paint1_linear_314_1390" x1="71.5" y1="83" x2="17.5444" y2="4.24798"
                              gradientUnits="userSpaceOnUse">
                <stop stop-color="#0F0F0F"/>
                <stop offset="0.4" stop-color="#2C2C2C"/>
                <stop offset="1" stop-color="#848484"/>
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div class="circle-button second_btn d-flex flex-column" style="padding: 30px">
          <h4>VISION</h4>
          <p class="">Perfection has to do with the end product, but excellence has to do with the process. Safenet creates extremely incredible products and its related components by its especial responses.</p>
        </div>
      </div>
      <div class="about-us section-padding">
        <div class="container">
          <div class="row" style="justify-content: space-between;">
            <div class="col-xl-6">
              <div class="about-left">
                <div class="section-title">
                  <h2 class="title">Who We Are</h2>
                </div>
                <div class="about-desc">
                  <p class="about-desc-p">
                   Safenet is one and only Bangladeshi derivation of structured LAN Cabling
                    brand since 2010.<br>
                    After its inception, Safenet is always looking forward to manage
                    proficiency, also align with the cutting-edge technology to facilitate
                    enhanced ICT Infrastructure solutions. We strongly believe that the
                    relentless effort towards quality and continuous improvement is the only
                    key to long term success.<br>
                    “Digital Bangladesh” with this slogan Bangladesh is fast pacing toward
                    the title of “Developing country”; Safenet – aligned its core goal with this
                    concept and contributing every possible aspect of “Digital
                    Transformation” of the country. Safenet brand which develops and
                    delivers high-quality components, calculated to match the needs in the
                    different vertical markets of ICT industry.<br>
                    Safenets’ essential focus is to dependably present functional and
                    lucrative products while maintaining outstanding customer satisfaction
                    – also continuing towards the exploration of newer horizons. Safenet
                    covers the entire value chain from the development of new products
                    over logistics, marketing and distribution to sales mixing support at the
                    point of purchase – Safenet offers high effectiveness with utmost
                    competitiveness.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="about-right">
                <img src="./About_Image/Who-we-are.png" alt=""  class="rounded-4 img-fluid"/>
              </div>
            </div>
          </div>
          </div>
        </div>
      <div class="container">
        <div class="row mission-vision">
          <div class="col-xl-8">
            <div class="left-content-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                <g clip-path="url(#clip0_3795_2164)">
                  <mask id="mask0_3795_2164" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0"
                        width="60"
                        height="60">
                    <path d="M60 0H0V60H60V0Z" fill="white"/>
                  </mask>
                  <g mask="url(#mask0_3795_2164)">
                    <path
                        d="M29.42 30.0037L42.0753 17.3484M42.0753 17.3484V8.91149L49.2881 2L50.5122 8.91149L57.4237 10.1356L50.5122 17.3484H42.0753ZM52.6215 15.2392C55.3635 19.4577 56.8399 24.5197 56.8399 30.0037C56.8399 45.1902 44.6064 57.4237 29.42 57.4237C14.2335 57.4237 2 45.1902 2 30.0037C2 14.8173 14.2335 2.5838 29.42 2.5838C34.904 2.5838 39.9661 4.06027 44.1846 6.80226M42.0753 30.0037C42.0753 36.9931 36.4093 42.6592 29.42 42.6592C22.4306 42.6592 16.7646 36.9931 16.7646 30.0037C16.7646 23.0144 22.4306 17.3484 29.42 17.3484C36.4093 17.3484 42.0753 23.0144 42.0753 30.0037Z"
                        stroke="#00BF63" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"
                        stroke-linejoin="round"/>
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_3795_2164">
                    <rect width="60" height="60" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <h4>Our Mission</h4>
              <p>Safenet has devoted to develop target oriented quality management system guarantees
                that continuous improvements are made to products, services, and processes for improving
                customers’ Fiber and Copper Infrastructure Solution. Focusing on this, safenet has been the
                leading Bangladeshi Brand.<br>
                Channel is the end-to-end cabling link from an active device to the network interface
                while Permanent Link refers to the ‘permanent’ or fixed part of the network which
                comprises of components that will permanently stay in place such as a Patch panel, LAN
                cable and an outlet.<br>
                When it comes to the performance, product guaranteed for the channel performance
                simply means that it works well with specified components in the channel link. For
                permanent link, as there are less components are involved, each components need to
                have better performance to ensure the link will work well. These two can be tested using
                FLUKE field tester, while connecting hardware testing requires a more precise Network
                Analyzer (NA). Safenet products are tested by Network Analyzer to ensure its maximum
                possible quality.
              </p>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="left-content-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                <path
                    d="M30.0003 13.8578C25.5145 14.0472 21.2633 15.9141 18.0885 19.0889C14.9137 22.2637 13.0468 26.5149 12.8574 31.0007C12.8924 35.5457 14.6455 39.9091 17.7646 43.215C18.8377 44.0703 19.7185 45.1421 20.3499 46.3604C20.9812 47.5788 21.3489 48.9165 21.4289 50.2864C21.4289 50.8547 21.6546 51.3998 22.0565 51.8016C22.4584 52.2035 23.0034 52.4293 23.5717 52.4293H36.4289C36.9972 52.4293 37.5422 52.2035 37.9441 51.8016C38.346 51.3998 38.5717 50.8547 38.5717 50.2864C38.6389 48.9243 38.9969 47.5924 39.6215 46.3801C40.2462 45.1678 41.123 44.1032 42.1932 43.2578C43.8012 41.6535 45.0685 39.7407 45.9192 37.6345C46.7698 35.5282 47.1861 33.2718 47.1432 31.0007C47.1449 28.6755 46.6835 26.3732 45.7858 24.2282C44.8881 22.0833 43.5721 20.1386 41.9146 18.5078C40.3402 16.9759 38.4747 15.7751 36.4283 14.9764C34.3819 14.1777 32.1962 13.7974 30.0003 13.8578ZM39.3003 40.1721C36.8726 42.2373 35.1807 45.0345 34.4789 48.1436H25.5217C24.8263 45.0634 23.1765 42.2813 20.8074 40.1936C18.4862 37.691 17.1799 34.4138 17.1432 31.0007C17.2949 27.6396 18.6981 24.4567 21.0772 22.0776C23.4563 19.6985 26.6392 18.2953 30.0003 18.1436C31.6306 18.087 33.2555 18.3617 34.7766 18.951C36.2978 19.5403 37.6836 20.432 38.8503 21.5721C40.1129 22.7974 41.1177 24.2626 41.8059 25.8818C42.494 27.501 42.8516 29.2414 42.8574 31.0007C42.8953 32.6936 42.5945 34.377 41.9726 35.952C41.3507 37.527 40.4203 38.9618 39.236 40.1721H39.3003Z"
                    fill="#A619AD"/>
                <path
                    d="M36.4277 54.5723H23.5705C23.0022 54.5723 22.4572 54.7981 22.0553 55.2C21.6534 55.6018 21.4277 56.1469 21.4277 56.7152C21.4277 57.2835 21.6534 57.8286 22.0553 58.2304C22.4572 58.6323 23.0022 58.858 23.5705 58.858H36.4277C36.996 58.858 37.541 58.6323 37.9429 58.2304C38.3448 57.8286 38.5705 57.2835 38.5705 56.7152C38.5705 56.1469 38.3448 55.6018 37.9429 55.2C37.541 54.7981 36.996 54.5723 36.4277 54.5723Z"
                    fill="#A619AD"/>
                <path
                    d="M30.0003 9.57143C30.5686 9.57143 31.1137 9.34566 31.5155 8.9438C31.9174 8.54194 32.1432 7.99689 32.1432 7.42857V3.14286C32.1432 2.57454 31.9174 2.02949 31.5155 1.62763C31.1137 1.22576 30.5686 1 30.0003 1C29.432 1 28.8869 1.22576 28.4851 1.62763C28.0832 2.02949 27.8574 2.57454 27.8574 3.14286V7.42857C27.8574 7.99689 28.0832 8.54194 28.4851 8.9438C28.8869 9.34566 29.432 9.57143 30.0003 9.57143Z"
                    fill="#A619AD"/>
                <path
                    d="M6.42857 28.8574H2.14286C1.57454 28.8574 1.02949 29.0832 0.627628 29.4851C0.225765 29.8869 0 30.432 0 31.0003C0 31.5686 0.225765 32.1137 0.627628 32.5155C1.02949 32.9174 1.57454 33.1432 2.14286 33.1432H6.42857C6.99689 33.1432 7.54194 32.9174 7.9438 32.5155C8.34566 32.1137 8.57143 31.5686 8.57143 31.0003C8.57143 30.432 8.34566 29.8869 7.9438 29.4851C7.54194 29.0832 6.99689 28.8574 6.42857 28.8574Z"
                    fill="#A619AD"/>
                <path
                    d="M57.8562 28.8574H53.5705C53.0022 28.8574 52.4572 29.0832 52.0553 29.4851C51.6534 29.8869 51.4277 30.432 51.4277 31.0003C51.4277 31.5686 51.6534 32.1137 52.0553 32.5155C52.4572 32.9174 53.0022 33.1432 53.5705 33.1432H57.8562C58.4246 33.1432 58.9696 32.9174 59.3715 32.5155C59.7733 32.1137 59.9991 31.5686 59.9991 31.0003C59.9991 30.432 59.7733 29.8869 59.3715 29.4851C58.9696 29.0832 58.4246 28.8574 57.8562 28.8574Z"
                    fill="#A619AD"/>
                <path
                    d="M51.2138 9.78523C50.8123 9.38612 50.2692 9.16211 49.7031 9.16211C49.137 9.16211 48.5938 9.38612 48.1924 9.78523L45.1495 12.8066C44.9487 13.0059 44.7892 13.2429 44.6804 13.504C44.5717 13.7651 44.5156 14.0452 44.5156 14.3281C44.5156 14.611 44.5717 14.891 44.6804 15.1522C44.7892 15.4133 44.9487 15.6503 45.1495 15.8495C45.3497 16.0481 45.5872 16.2052 45.8483 16.3119C46.1094 16.4185 46.3889 16.4726 46.6709 16.4709C46.9529 16.4726 47.2325 16.4185 47.4936 16.3119C47.7547 16.2052 47.9921 16.0481 48.1924 15.8495L51.2138 12.8066C51.6129 12.4052 51.8369 11.862 51.8369 11.2959C51.8369 10.7298 51.6129 10.1867 51.2138 9.78523Z"
                    fill="#A619AD"/>
                <path
                    d="M14.8491 15.8491C15.0499 15.6499 15.2094 15.4129 15.3182 15.1518C15.4269 14.8906 15.483 14.6106 15.483 14.3277C15.483 14.0448 15.4269 13.7647 15.3182 13.5036C15.2094 13.2425 15.0499 13.0055 14.8491 12.8062L11.8062 9.78479C11.3963 9.43374 10.869 9.2503 10.3297 9.27113C9.79037 9.29196 9.27879 9.51553 8.89716 9.89716C8.51553 10.2788 8.29196 10.7904 8.27113 11.3297C8.2503 11.869 8.43374 12.3963 8.78479 12.8062L11.8062 15.8491C12.0065 16.0477 12.2439 16.2048 12.505 16.3115C12.7661 16.4181 13.0457 16.4722 13.3277 16.4705C13.6097 16.4722 13.8892 16.4181 14.1503 16.3115C14.4114 16.2048 14.6489 16.0477 14.8491 15.8491Z"
                    fill="#A619AD"/>
              </svg>
              <h4>Our Vission</h4>
              <p>Our vision is to become a trusted leader in aged care within the Burnett community and beyond. We aim
                to set a benchmark for excellence by fostering an inclusive and nurturing atmosphere where older adults can
                thrive. Through innovation, empathy, and unwavering commitment, we envision a future where every senior
                receives the quality care they deserve, empowering them to age with grace, comfort, and purpose.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="section-padding">
        <div class="container">
          <div class="row" style="justify-content: space-between;">
            <div class="col-xl-6">
              <div class="about-right">
                <img src="./About_Image/Partner.png" alt=""  class="rounded-4 img-fluid"/>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="about-left">
                <div class="section-title">
                  <h2 class="title">Partner</h2>
                </div>
                <div class="about-desc">
                  <p class="about-desc-p">
                    We work closely with our Partners and provide them support and
                    training that ensure right products and solution are tailored to meet the
                    demands of today's business environments.<br>
                    We also serve limited number of Solution Partners; as such we can
                    assure unique technical support. However, on special cases we work as
                    an extension of them to provide best solution that might be needed.
                    We always highly appreciate request who wants to become our Partner.<br>
                    There is no limitless offer to become of our exclusive partner but few
                    rationales may differentiate your business by ensuring customer
                    satisfaction and leading to repeat business. Once you will be connected
                    with us, product quality is guaranteed right from the beginning.
                    Moreover, you can get assistance from us in order to successfully attract
                    and retain customers.<br>
                    Be the first to join with us and helping you to get it right.

                  </p>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      <div class="section-padding">
        <div class="container">
          <div class="card-heading text-center">
            <h1>Unlock Valuable Benefits <br>as soon as you join</h1>
          </div>
          <div class="about-cards" style="gap: 2.5rem; margin-top: 3rem;">
            <div class="card rounded-4" style="">
              <div class="rounded-circle" style="background: #00BF63; padding: 30px;" >
                <img src="./About_Image/light-bulb.svg" alt="Card image cap" width="40" height="40">
              </div>
              <div class="card-body">
                <h5 class="card-title text-center">Vetted Solution</h5>
                <p class="card-text">When you will work with us, you will have our
                  products and solutions at your fingertips. Count
                  on our expertise to deliver the knowledge and
                  skills to manage your resources according to
                  your target market. This liberates up your team
                  to focus on the core aspects of your business,
                  rather than on trying to reinvent the wheel.</p>
              </div>
            </div>
            <div class="card rounded-4" style="">
              <div class="rounded-circle" style="background: #00BF63; padding: 30px;" >
                <img src="./About_Image/tag.svg" alt="Card image cap" width="40" height="40">
              </div>
              <div class="card-body">
                <h5 class="card-title text-center">Competitive Pricing</h5>
                <p class="card-text">In most cases, we offer partners large discounts
                on our products to win the deal. This can set
                them apart from the competitors when providing
                a quote to their customers. This preferred pricing
                will not only increase their profit margins but also
                help their business grow at a faster rate.</p>
              </div>
            </div>
            <div class="card rounded-4" style="">
              <div class="rounded-circle" style="background: #00BF63; padding: 30px;" >
                <img src="./About_Image/note.svg" alt="Card image cap" width="40" height="40">
              </div>
              <div class="card-body">
                <h5 class="card-title text-center">Zero Inventory</h5>
                <p class="card-text">Best part when you will become a Partner, you
                are neither involved in product development nor
                in inventory management. Your main job is to
                resell products that are already competent in
                the market and you can make a profit without
                holding stock. Also, you pay to supplier only for
                products that the customer has ordered.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      circularText: 'DURABLE•OUR BIG COLLECTION•FLEXIBLE•'
    }
  },
  methods: {
    stopVideo() {
      if (this.$refs.videoIframe) {
        const iframeSrc = this.$refs.videoIframe.src
        this.$refs.videoIframe.src = iframeSrc
      }
    }
  },
  mounted() {
    const modal = document.getElementById('exampleModal')
    if (modal) {
      modal.addEventListener('hidden.bs.modal', this.stopVideo)
    }
  }
}
</script>

<style scoped>
@font-face {
    font-family: 'Poppins';
    src: url('../assets/Poppins/Poppins-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/Poppins/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/Poppins/Poppins-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

.about-cards {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 400px));
  justify-content: center; /* Center the grid items when fewer than 3 cards in a row */
  gap: 2.5rem;
  margin-top: 3rem;
  padding: 1rem;
}
.about-cards .card {
  border: 1px solid transparent;
  text-align: justify;
  justify-content: center;
  align-items: center;
  padding: 28px;
  box-shadow: 0px 1px 4px -1px rgba(36, 42, 66, 0.06), 0px 10px 24px -8px rgba(36, 42, 66, 0.04), 0px 20px 70px -10px rgba(36, 42, 66, 0.08);
  max-width: 400px;
}
.about-cards .card .card-body {
  padding: 2.5rem 0 0 0;
}
.card-heading h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 54px;
  line-height: 64px;
}
.left-content-3 {
    padding: 56px;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 1px 4px -1px rgba(36, 42, 66, 0.06), 0px 10px 24px -8px rgba(36, 42, 66, 0.04), 0px 20px 70px -10px rgba(36, 42, 66, 0.08);
    min-height: 670px;
    max-height: 850px;
}
.left-content-3 h4 {
    margin-top: 40px;
    margin-bottom: 16px !important;
    color: #424242;
    font-family: 'Poppins', sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    text-transform: capitalize;
}
.left-content-3 p {
    color: #424242;
    font-family: 'Poppins', sans-serif;
    text-align: justify;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-transform: capitalize;
}
.section-padding {
    padding: 5rem 0;
}
.mission-vision {
    padding: 0;
}
.section-title {
    margin-bottom: 2.5rem;
}
.title {
    margin-top: 18px;
    color: #164834;
    font-family: "Poppins", sans-serif;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: 0.27px;
    text-transform: capitalize;
}
.about-desc-p {
    color: #344038;
    text-align: justify;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.08px;
    text-transform: capitalize;
}
.about-right img {
  width: 100%;
  height: auto; /* Maintains aspect ratio */
  object-fit: cover; /* Optional: Ensures the image fits nicely */
}
.modal-dialog{
  margin-top: 7%;
}
.story-heading {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 52px */
  letter-spacing: 0.2px;
}

.circle-button h4 {
  color: #222;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 41.6px */
  letter-spacing: 1.6px;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.circle-button {
  position: relative;
  overflow: hidden;
}

.circle-button p {
  color: #262626;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.08px;
  transition: transform 0.5s ease, opacity 0.5s ease;
  position: absolute;
  bottom: -90px;
  transform: translateY(20px);
  opacity: 0;
  padding: 30px;
}

.circle-button:hover p {
  transform: translateY(-110px);
  opacity: 1;
}

.circle-button:hover h4 {
  transform: translateY(-20px);
}

.second_btn:hover h4 {
  transform: translateY(-40px) !important;
}
.second_btn:hover p{
  transform: translateY(-100px) !important;
}

.breadcrumb {
  background: #F6F6F6;
  padding: 20px 80px;
}

.breadcrumb-item a {
  text-decoration: none;
  color: #1E1E1E;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.5px;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: '';

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: solid #1E1E1E;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  margin-right: 0px;
  transform: rotate(-45deg);
  transition: transform 0.3s ease;
  height: 0;
  width: 0;
}

.circular-text {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 2rem auto;
}

.circular-text span {
  color: #222;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.big-number {
  font-size: 150px;
  font-style: normal;
  font-weight: 700;
  line-height: 150px;
  background: linear-gradient(117deg, #1AE5FE 8.83%, #105BCC 108.23%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.circle-button {
  width: 300px;
  height: 300px;
  border: 1px solid #ddd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  transition: all 0.3s ease;
  color: #222;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: 1.6px;
}

.circle-button:hover {
  background: #f8f9fa;
  cursor: pointer;
}

.story-heading {
  background: linear-gradient(91deg, #1AE5FE 14.22%, #105BCC 86.23%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.2px;
  margin-bottom: 16px;
}

.text-muted {
  color: #000;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
  letter-spacing: 0.08px;
  margin-bottom: 50px;
}

.circular-text-container {
  width: 300px;
  height: 300px;
  margin: 2rem auto;
}

.circular-text {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: rotate 40s linear infinite;
}

.circular-text span {
  position: absolute;
  left: 50%;
  font-size: 20px;
  transform-origin: 0 150px;
  text-transform: uppercase;
}

.year {
  color: #064D85;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.16px;
}

.lan {
  color: #1E1E1E;
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 41.6px */
  letter-spacing: 0.16px;
}

.lan-text {
  color: #262626;
  text-align: justify;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.08px;
}

@media only screen and (max-width: 375px) {
  .story-heading {
    background: linear-gradient(91deg, #1AE5FE 14.22%, #105BCC 86.23%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 26px */
    letter-spacing: 0.1px;
    margin-bottom: 16px;
  }

  .text-muted {
    color: #262626;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.08px;
    margin-bottom: 50px;
  }

  .story_area {
    padding: 20px !important;
  }

  .lan {
    color: #1E1E1E;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
    letter-spacing: 0.1px;
  }

  .modal-dialog {
    max-width: 90% !important;
    margin: 50% auto 0;

  }

  .modal-body {
    height: 400px !important;
  }

}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .story-heading {
    background: linear-gradient(91deg, #1AE5FE 14.22%, #105BCC 86.23%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 26px */
    letter-spacing: 0.1px;
    margin-bottom: 16px;
  }

  .text-muted {
    color: #262626;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.08px;
    margin-bottom: 50px;
  }

  .story_area {
    padding: 20px !important;
  }

  .lan {
    color: #1E1E1E;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
    letter-spacing: 0.1px;
  }

  .modal-dialog {
    max-width: 90% !important;
    margin: 40% auto 0;

  }

  .modal-body {
    height: 400px !important;
  }

}

@media only screen and (min-width: 426px) and (max-width: 767px) {

  .story-heading {
    background: linear-gradient(91deg, #1AE5FE 14.22%, #105BCC 86.23%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 26px */
    letter-spacing: 0.1px;
    margin-bottom: 16px;
  }

  .text-muted {
    color: #262626;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.08px;
    margin-bottom: 50px;
  }

  .story_area {
    padding: 20px !important;
  }

  .lan {
    color: #1E1E1E;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
    letter-spacing: 0.1px;
  }

  .modal-dialog {
    max-width: 90% !important;
    margin: 20% auto 0;

  }

  .modal-body {
    height: 400px !important;
  }

}
@media (max-width: 1200px) {
  .about-cards {
    grid-template-columns: repeat(2, minmax(0, 400px));
  }
}

@media (max-width: 768px) {
  .about-cards {
    grid-template-columns: repeat(1, minmax(0, 400px));
  }
}

@media only screen and  (min-width: 768px) and (max-width: 991px) {
  .story_area {
    padding: 40px !important;
  }

  .modal-dialog {
    max-width: 80% !important;
    margin: 7% auto 0;

  }

  .modal-body {
    height: 450px !important;
  }

}

@media only screen and (min-width: 992px) and (max-width: 1100px) {
  .story_area {
    padding: 50px !important;
  }

  .modal-dialog {
    max-width: 60% !important;
    margin: 7% auto 0;

  }

  .modal-body {
    height: 500px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .row.mission-vision {
    gap: 5rem;
  }
  .left-content-3 {
    min-height: 0;
    max-height: 1320px;
  }
}

@media only screen and (max-width: 1366px) {

}

@media only screen and (max-width: 1440px) {

}
</style>